import { useContext, useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { block } from 'bem-cn';
import loadable from '@loadable/component';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Title from 'components/Title';
import MediumContainer from 'components/Container/_medium';
import Container from 'components/Container';
import { DeviceContext } from 'layouts/context';

import { sidebarDocsMenu, sidebarFaqMenu } from 'assets/constants';

const Sidebar = loadable(() => import('components/Sidebar'));
const MobileSidebar = loadable(() => import('components/Sidebar/_mobile'));

import {
	h2,
	h3,
	h4,
	insideLink,
	ul,
	ol,
	li,
	downloadCard,
	downloadCardBlock,
	customTop,
	img,
	table,
	description,
	renderAst,
} from 'components/markDownComponents';

const renderComToolkit = {
	h2: h2,
	h3: h3,
	h4: h4,
	ul: ul,
	ol: ol,
	li: li,
	img: img,
	table: table,
	'inside-link': insideLink,
	'download-card': downloadCard,
	'download-card-block': downloadCardBlock,
	space: customTop,
	description: description,
};

const renderCom = renderAst(renderComToolkit);

import './style.scss';

const cn = block('docs-template');

const formatTables = () => {
	const tables = document.querySelectorAll('#docs-table');
	tables.forEach((table) => (table.rows[0].cells[0].colSpan = 2));
};

const DocsTemplate = ({ data }) => {
	const { htmlAst } = data.markdownRemark;
	const { heading, title, seo, parentPage } = data.markdownRemark.frontmatter;
	const { isTablet } = useContext(DeviceContext);

	useEffect(() => {
		formatTables();
	});

	const headingPage = useMemo(
		() => parentPage || heading,
		[parentPage, heading]
	);

	const menuSideBar = useMemo(() =>
		seo.link.startsWith('/faq/') ? sidebarFaqMenu : sidebarDocsMenu
	);

	return (
		<Layout seo={seo}>
			<Container
				className={cn('wrap', { faq: seo.link.startsWith('/faq/') })}
				generalContainerClassName={cn('container')}
			>
				{isTablet ? (
					<div className={cn('mobile-sidebar')}>
						<MobileSidebar
							title={title}
							heading={headingPage}
							menu={menuSideBar}
						/>
					</div>
				) : (
					<Sidebar heading={headingPage} menu={menuSideBar} />
				)}
				<MediumContainer
					className={cn('mediumContainer', { wrap: true })}
					generalContainerClassName={cn('container')}
				>
					<Heading color='general' weight='bold' className={cn('title')}>
						{heading ?? parentPage}
						{title && (
							<>
								{' '}
								<Title
									color='general'
									level={1}
									weight='bold'
									className={cn('title')}
								>
									&#8212; {title}
								</Title>
							</>
						)}
					</Heading>
					{renderCom(htmlAst)}
				</MediumContainer>
			</Container>
		</Layout>
	);
};

export default DocsTemplate;

export const pageQuery = graphql`
	query ($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			htmlAst
			frontmatter {
				heading
				title
				parentPage
				seo {
					description
					image
					link
					title
				}
			}
		}
	}
`;
